import React, { useState } from "react"
import { Link } from "gatsby"
import { navMenuItems } from "components/Data/MenuItems"
import styled from "styled-components"
import he from "he"
import Hamburger from "components/Hamburger"
import Underline from "components/Ui/Underline"
import device from "utils/media"

const HeaderMenuStyled = styled.nav`
  display: flex;
  margin-left: auto;
`

const HeaderMenuList = styled.ul`
  margin: 0;
  padding: 0;
  display: none;
  margin-right: 50px;
  @media ${device.lg} {
    display: inline-block;
  }
`

const HeaderMenuItem = styled.li`
  display: block;
  float: left;
  position: relative;
  padding-bottom: 0.5rem;
  font-weight: 500;

  &.menu-item-has-children {
    > ul {
      transition: all 0.5s ease;
      opacity: 0;
      height: 0;
    }

    > ul.open {
      opacity: 1;
      height: auto;
    }

    > ul.closed {
      opacity: 0;
      height: 0;
    }
  }

  .sub-menu {
    // background-color: ${props => props.theme.color.teal};
    background-color: #000;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    min-width: 18rem;
    position: absolute;
    transition: all 0.5s ease;
    margin-top: 0.5rem;
    left: 0;
    overflow-y: hidden;

    li {
      text-transform: uppercase;
      font-size: 1.3rem;
      width: 100%;
      font-weight: 400;
      padding: 0;
      &:hover {
        background-color: #454548;
      }

      a {
        width: 100%;
        display: block;
        padding: 1rem;
        color: #fff !important;
      }
    }
  }
`

export const NavExtLink = styled.a`
  cursor: pointer;
  margin-right: 35px;
  text-transform: uppercase;
  font-size: 1.4rem;
  position: relative;
  text-decoration: none;
  &:last-of-type {
    padding-right: 0;
  }
  &:hover {
    color: black;
  }
`

export const NavLink = styled(Link)`
  margin-right: 35px;
  text-transform: uppercase;
  font-size: 1.4rem;
  position: relative;
  text-decoration: none;
  &:last-of-type {
    padding-right: 0;
  }
  &:hover {
    color: black;
  }
`

const NavMenuDesktop = ({ navDrawOpen, burgerClick }) => {
  const [desktopSubMenuIsOpen, setDesktopSubMenuIsOpen] = useState(false)

  return (
    <>
      <HeaderMenuStyled className="nav-desktop">
        <HeaderMenuList>
          {navMenuItems.items.map((item, i) => {
            const hasChildItems =
              item.subMenuItems && item.subMenuItems.length > 0

            return (
              <HeaderMenuItem
                key={i}
                className={`${hasChildItems ? "menu-item-has-children" : ""}`}
                onMouseEnter={() => hasChildItems && setDesktopSubMenuIsOpen(true)}
                onMouseLeave={() => hasChildItems && setDesktopSubMenuIsOpen(false)}
              >
                {hasChildItems ? (
                  <NavExtLink
                    className="navbar-item"
                    activeClassName="active"
                    href={null}
                    rel="noopener noreferrer"
                  >
                    <Underline linkColor="black">
                      {he.decode(item.title)}
                    </Underline>
                  </NavExtLink>
                ) : (
                  <NavLink
                    className="navbar-item"
                    activeClassName={item.path !== "/" ? "active" : ""}
                    partiallyActive={true}
                    to={item.path}
                  >
                    <Underline linkColor="black">
                      {he.decode(item.title)}
                    </Underline>
                  </NavLink>
                )}
                <>
                  {hasChildItems && (
                    <ul
                      className={`sub-menu ${
                        desktopSubMenuIsOpen ? "open" : "closed"
                      }`}
                    >
                      {item.subMenuItems.map((subitem, idx) => (
                        <li key={idx}>
                          <Link
                            className="sub-menu-item"
                            activeClassName="active"
                            to={subitem.path}
                          >
                            {he.decode(subitem.title)}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              </HeaderMenuItem>
            )
          })}
        </HeaderMenuList>
      </HeaderMenuStyled>
      <Hamburger navDrawOpen={navDrawOpen} burgerClick={burgerClick} />
    </>
  )
}

export default NavMenuDesktop

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-identity-resolution-providers-js": () => import("./../../../src/pages/identity-resolution-providers.js" /* webpackChunkName: "component---src-pages-identity-resolution-providers-js" */),
  "component---src-pages-identity-verification-providers-js": () => import("./../../../src/pages/identity-verification-providers.js" /* webpackChunkName: "component---src-pages-identity-verification-providers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-old-js": () => import("./../../../src/pages/indexOld.js" /* webpackChunkName: "component---src-pages-index-old-js" */),
  "component---src-pages-privacy-policy-b-2-b-js": () => import("./../../../src/pages/privacy-policy-b2b.js" /* webpackChunkName: "component---src-pages-privacy-policy-b-2-b-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-whats-my-kas-segment-js": () => import("./../../../src/pages/whats-my-kas-segment.js" /* webpackChunkName: "component---src-pages-whats-my-kas-segment-js" */)
}


import React from "react"
import styled from "styled-components"
import Link from "components/Link"
import Underline from "components/Ui/Underline"
import device from "utils/media"

const NavItem = styled.li`
  font-size: ${props =>
    props.size === "small" ? "12px" : props.theme.text.md};

  @media ${device.sm} {
    font-size: ${props => (props.size === "small" ? "14px" : "32px")};
  }
`

const SideNavLink = styled(({ linkColor, ...rest }) => <Link {...rest} />)`
  color: ${props => props.linkColor};
  &:hover,
  &:focus,
  &:active {
    color: white;
  }
`

const NavGroupStyled = styled.nav`
  margin-bottom: 10px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

  @media ${device.md} {
    margin-bottom: 30px;
    /*width: 100%;*/
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media ${device.sm} {
      flex-direction: column;
    }
  }

  li {
    padding: 0;
    text-transform: uppercase;
    margin: 0 0 10px 2px;

    font-weight: ${props => (props.size === "small" ? "400" : "700")};
  }
`

SideNavLink.defaultProps = {
  linkColor: "#999"
}

const NavGroupSecondary = props => {
  return (
    <NavGroupStyled {...props}>
      <ul>
        {props.items.map((item, i) => (
          <NavItem key={i} size={props.size}>
            {item.link ? (
              <SideNavLink
                linkColor={props.linkColor}
                size={props.size}
                to={`${item.link}/`}
                onClick={props.handleClick}
              >
                <Underline linkColor="white">{item.title}</Underline>
              </SideNavLink>
            ) : (
              <span style={{ color: props.linkColor }}>{item.title}</span>
            )}
          </NavItem>
        ))}
      </ul>
    </NavGroupStyled>
  )
}

export default NavGroupSecondary

import React, { Component } from "react"
import styled from "styled-components"
import posed from "react-pose"

const UnderlineWrap = styled.span`
  position: relative;
  a {
    &:focus {
      color: ${props => props.linkColor};
    }
  }

  &:hover {
    svg {
      transform: translateY(-50%) translateX(3px);
    }
  }
`

/*
const ArrowStyled = styled(ArrowIcon)`
  display: inline-block;
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);

  path {
    stroke: ${props => (props.color ? props.color : 'black')};
  }
`
*/

//not sure how to pose on pseudo elements - have created a span for the underline for now.
export const UnderlineElement = styled(
  posed.span({
    show: {
      y: props => (props.initiallyVisible ? "6px" : "3px"),
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 500,
      },
    },
    hide: {
      y: props => (props.initiallyVisible ? "3px" : 0),
      opacity: props => (props.initiallyVisible ? 1 : 0),
      duration: 0,
      transition: {
        type: "spring",
      },
    },
  })
)`
  position: absolute;
  left: 1%;
  bottom: 0px;
  width: 98%;
  opacity: 0;
  height: ${props => (props.size === "small" ? "1px" : "2px")};
  background-color: ${props => props.linkColor};
  display: block;

  .active & {
    opacity: 1 !important;
    transform: translateY(3px) !important;
  }
`

UnderlineElement.defaultProps = {
  size: "default",
  linkColor: "white",
}

export default class Underline extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hovered: false,
    }
  }

  handleHover = () => {
    this.setState(prevState => ({
      hovered: !prevState.hovered,
    }))
  }

  render() {
    return (
      <UnderlineWrap
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}
      >
        {this.props.children}
        <UnderlineElement
          initiallyVisible={this.props.initiallyVisible ? true : false}
          linkColor={this.props.linkColor}
          size={this.props.size}
          className={this.props.className}
          initialPose="hide"
          pose={this.state.hovered ? "show" : "hide"}
        />
        {/*this.props.arrow && <ArrowStyled color={this.props.arrow} />*/}
      </UnderlineWrap>
    )
  }
}

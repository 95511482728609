/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react"
import posed, { PoseGroup } from "react-pose"
import { ThemeProvider } from "styled-components"
import { theme } from "./src/utils/theme"
import Header from "./src/components/Header"

// const timeout = 10;
const Transition = posed.div({
  enter: {
    ease: "linear",
    transition: {
      duration: 700,
      opacity: {
        duration: 700,
        delay: 400
      }
    },
    opacity: 1,
    x: 0,
    flip: false
  },
  flip: {
    transition: {
      ease: "linear"
    }
  },
  exit: {
    ease: "linear",
    opacity: 0,
    x: -20,
    flip: false,
    transition: {
      duration: 300,
      delay: 0
    }
  }
})

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Header />
      <PoseGroup flipMove={false}>
        <Transition key={props.location.pathname}>{element}</Transition>
      </PoseGroup>
    </ThemeProvider>
  )
}

const transitionDelay = 500

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    )
  }
  return false
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
    console.log(`IntersectionObserver is polyfilled`)
  }
}


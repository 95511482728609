import React from "react"
import styled from "styled-components"
import { ReactComponent as Logo } from "../../content/assets/winr-data-logo.svg"

export const LogoWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  top: 5px;
  svg {
    height: 48px;
    path {
      transition: ${props => props.theme.transition.default};
      fill: ${props => props.theme.color.teal};
      &.winr-data-logo_svg__b {
        fill: #ffffff;
      }
      &.winr-data-logo_svg__c {
        fill: #000000;
      }      
    }
  }
`

const HeaderLogo = () => (
  <LogoWrap>
    <Logo />
  </LogoWrap>
)

export default HeaderLogo

import React from "react"
import styled from "styled-components"
import { navMenuItems } from "components/Data/MenuItems"
import he from "he"
import Link from "components/Link"
import NavParentItem from "components/NavParentItem"
import Underline from "components/Ui/Underline"
import device from "utils/media"

const NavItem = styled.li`
  position: relative;
  font-size: ${props =>
    props.size === "small" ? "12px" : props.theme.text.md};

  @media ${device.sm} {
    font-size: ${props => (props.size === "small" ? "14px" : "32px")};
  }

  > ul li {
    font-size: ${props =>
      props.size === "small" ? "12px" : props.theme.text.sm};

    @media ${device.sm} {
      font-size: ${props => (props.size === "small" ? "14px" : "16px")};
    }
  }
`

const SideNavLink = styled(({ linkColor, ...rest }) => <Link {...rest} />)`
  color: ${props => props.linkColor};
  width: 100%;
  &:hover,
  &:focus,
  &:active {
    color: white;
  }
  cursor: pointer;
  span.symbol {
    position: absolute;
    left: -30px;
  }
`

const NavGroupStyled = styled.nav`
  margin-bottom: 10px;
  width: 90%;
  padding-left: 24px;
  margin-left: auto;
  margin-right: auto;
  // Make long menus scrollable on mobile
  overflow-y: auto;

  @media ${device.md} {
    padding-left: 30px;
    overflow-y: hidden;
  }

  > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: ${props =>
      props.size === "small" && !props.stackMobile ? "row" : "column"};
    justify-content: center;
    align-items: flex-start;

    @media ${device.sm} {
      flex-direction: column;
    }
  }

  > ul > li {
    text-align: left;
    width: 100%;
    position: relative;
    padding: 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    margin: 0 ${props => (props.size === "small" ? "10px" : 0)} 10px;

    font-weight: ${props => (props.size === "small" ? "400" : "700")};
  }
`

SideNavLink.defaultProps = {
  linkColor: "#999",
}

const NavGroupPrimary = props => {
  return (
    <NavGroupStyled>
      <ul>
        {navMenuItems.items.map((item, i) => {
          const hasChildItems =
            item.subMenuItems && item.subMenuItems.length > 0
          return (
            <NavItem key={i} size={props.size}>
              {hasChildItems ? (
                <NavParentItem
                  linkColor={props.linkColor}
                  item={item}
                  size={props.size}
                  handleClick={props.handleClick}
                />
              ) : (
                <SideNavLink
                  linkColor={props.linkColor}
                  size={props.size}
                  to={item.path}
                  onClick={props.handleClick}
                >
                  <Underline linkColor="white">
                    {he.decode(item.title)}
                  </Underline>
                </SideNavLink>
              )}
            </NavItem>
          )
        })}
      </ul>
    </NavGroupStyled>
  )
}

export default NavGroupPrimary

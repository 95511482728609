import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import classNames from "classnames"
import Headroom from "react-headroom"
import HeaderLogo from "components/HeaderLogo"
import NavMenuDesktop from "components/NavMenuDesktop"

const HeaderStyled = styled(Headroom)`
  position: absolute;
  width: 100%;
  z-index: 200;
  top: 0;
  left: 0;
  .headroom {
    position: fixed;
    transition: all 400ms ease-in-out;
    background-color: rgba(255, 255, 255, 0);
    svg {
      transform: scale(1);
      transition: transform 1500ms ease-in-out;
    }
  }

  .headroom--scrolled {
    transition: all 400ms ease-in-out;
  }

  .headroom--unfixed {
    position: fixed !important;
    top: 0;
    background-color: rgba(255, 255, 255, 0);
    opacity: 1;
    transform: translateY(0%);
  }

  .headroom--pinned {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.8);
    opacity: 1;
    transform: translateY(0%);
  }

  .headroom--unpinned {
    position: fixed;
    background-color: rgba(255, 255, 255, 0);
    opacity: 0;
    transform: translateY(-100%);
    svg {
      transform: scale(0.4);
    }
  }
`

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 18px 0 18px 0;

  .headroom--pinned & {
    padding: 18px 0 23px 0;
  }
`

const LogoLink = styled(Link)`
  position: relative;
  z-index: 900;

  &:focus {
    outline: none;
  }
`

const Header = () => {
  const [navDrawOpen, setnavDrawOpen] = useState(false)

  const handleBurgerClick = () => {
    setnavDrawOpen(!navDrawOpen)
  }

  return (
    <HeaderStyled disable={navDrawOpen}>
      <header
        className={classNames("container no-max", {
          "nav-drawer--open": navDrawOpen,
        })}
      >
        <HeaderContainer>
          <LogoLink to="/">
            <HeaderLogo navDrawOpen={navDrawOpen} />
          </LogoLink>

          <NavMenuDesktop
            navDrawOpen={navDrawOpen}
            burgerClick={handleBurgerClick}
          />
        </HeaderContainer>
      </header>
    </HeaderStyled>
  )
}

export default Header

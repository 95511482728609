const theme = {
  featureColor: "#fff",
  color: {
    black: "#000",
    teal: "#00c6bf",
    light_teal: "#29cdc9",
    orange: "#d74b38",
    red: '#DE0A1C',
    light_orange: "#d84b39",
    dark_orange: "#511008",
    dark_purple: "#19191d",
    offBlack: "#00313c",
    core_black: "rgb(0, 49, 60)",
    wireframes_black: "rgb(39, 40, 49)",
    green_50: "rgb(226, 252, 226)",
  },
  spacing: {
    xxs: "5px",
    xs: "10px",
    sm: "15px",
    base: "30px",
    md: "45px",
    lg: "60px",
    xl: "100px",
    xxl: "120px",
    xxxl: "155px",
    header: "200px",
    header_mobile: "120px",
  },
  text: {
    xxxs: "10px",
    xxs: "12px",
    xs: "14px",
    sm: "16px",
    sm_2: "18px",
    base: "18px",
    base_2: "20px",
    md: "24px",
    md_1: "28px",
    md_2: "30px",
    md_3: "33px",
    lg: "36px",
    xl: "40px",
    xl_2: "48px",
    xl_3: "56px",
    xxl: "60px",
    xxxl: "72px",
    xxxxl: "100px",
  },
  font: {
    body: "'Montserrat', Helvetica, Arial, sans-serif",
    body_alt: "Helvetica, Arial, sans-serif",
    feature: "'Rubik', Helvetica, Arial, sans-serif",
  },
  transition: {
    default: "all 0.35s ease",
    slow: "all 0.75s ease",
    cubic: "all 1s cubic-bezier(0.17, 0.67, 1, 1.23)",
  },
  timing: {
    cubic: "cubic-bezier(0.17, 0.67, 1, 1.23)",
  },
}

export { theme }

import React, { Component } from "react"
import styled, { createGlobalStyle } from "styled-components"
import NavDrawer from "components/NavDrawer"

const HamburgerStyled = styled.button`
  z-index: 999;
  will-change: transform;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  padding: 20px;
  margin-left: 20px;
  margin-right: -20px;
  border: none;
  background: none;

  &:focus {
    outline: none;
  }
`

class Hamburger extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hovered: false
    }
  }

  handleHover() {
    this.setState(prevState => ({
      hovered: !prevState.hovered
    }))
  }

  render() {
    const GlobalStyle = createGlobalStyle`
      html {
        margin-left: ${this.props.navDrawOpen && "calc(100vw - 100%)"} 
      }

      body {
        overflow: ${this.props.navDrawOpen && "hidden"};
      }
    `

    return (
      <>
        <GlobalStyle />

        <HamburgerStyled
          className={`hamburger hamburger--spin ${this.props.navDrawOpen &&
            "is-active"}`}
          type="button"
          onClick={this.props.burgerClick}
          onMouseEnter={() => this.handleHover()}
          onMouseLeave={() => this.handleHover()}
          hovered={this.state.hovered}
          active={this.props.navDrawOpen}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </HamburgerStyled>

        <NavDrawer
          {...this.props}
          handleClick={this.props.burgerClick}
          open={this.props.navDrawOpen}
        />
      </>
    )
  }
}

export default Hamburger

const navMenuItems = {
  items: [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "KAS Demo",
      path: "/whats-my-kas-segment/",
    },
    {
      title: "Contact",
      path: "/contact/",
    },        
  ],
  footerSolutionsMenuItems: [
    {
      title: "KAS Demo",
      path: "/whats-my-kas-segment/",
    }
  ],
  footerCompanyMenuItems: [
    {
      title: "Contact",
      path: "/contact/",        
    }    
  ]  
}

export { navMenuItems }

import React from "react"
import styled from "styled-components"
import posed from "react-pose"
import NavGroupSecondary from "components/NavGroupSecondary"
import NavGroupPrimary from "components/NavGroupPrimary"
import device from "utils/media"

const NavAnimation = {
  open: {
    x: 0,
    staggerChildren: 100
  },
  closed: {
    x: "100%",
    transition: {
      duration: 300
    }
  }
}

const NavDrawerStyled = styled(posed.div(NavAnimation))`
  background-color: black;
  width: 100%;
  min-height: 100vh; 
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%);
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: ${props => props.theme.spacing.lg} 15px;
  text-align: center;
  padding-top: ${props => props.theme.spacing.xl};
  z-index: 800;

  @media ${device.sm} {
    width: 500px;
    right: -100px;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding: 120px 135px 70px 30px;
  }

  > div {
    padding-top: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;

    @media ${device.sm} {
      padding-top: 0;
    }
  }

  .bottom {
    margin-top: auto;
  }
`

NavGroupSecondary.defaultProps = {
  linkColor: "#999"
}

const NavDrawer = (props) => {
  return (
    <NavDrawerStyled initialPose="closed" pose={props.open ? "open" : "closed"}>
      <div style={{ width: '100%' }}>
        <NavGroupPrimary
          handleClick={props.handleClick}
          linkColor={props => props.theme.color.teal}
        />
      </div>
    </NavDrawerStyled>
  )
}

export default NavDrawer

import React, { useState } from "react"
import posed from "react-pose"
import styled from "styled-components"
import he from "he"
import Link from "components/Link"
import device from "utils/media"

const SideNavLink = styled(({ linkColor, ...rest }) => <Link {...rest} />)`
  color: ${props => props.linkColor};
  width: 100%;
  &:hover,
  &:focus,
  &:active {
    color: white;
  }
  cursor: pointer;
  span.symbol {
    position: absolute;
    left: -24px;
    @media ${device.md} {
      left: -30px;
    }
  }
`

const SubMenu = styled(
  posed.ul({
    transition: { duration: 0 },
    open: {
      opacity: 1,
      delayChildren: 150,
      staggerChildren: 25,
      duration: 50,
      right: 0,
    },
    closed: {
      opacity: 0,
      duration: 50,
      staggerChildren: 25,
      right: -50,
    },
  })
)`
  list-style: none;
  padding: 20px 0 10px 0px;
  padding-left: 0;
  position: relative;
  width: 100%;

  &.open {
    display: block;
  }

  &.closed {
    display: none;
  }

  li {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
    font-weight: 400;
  }
`

const NavParentItem = ({ linkColor, item, size, handleClick }) => {
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false)

  const handleParentItemClick = () => {
    if (subMenuIsOpen) {
      handleClick()
    }
    setSubMenuIsOpen(!subMenuIsOpen)
  }

  return (
    <>
      <SideNavLink
        linkColor={linkColor}
        data-parent={item.path}
        size={size}
        onClick={handleParentItemClick}
      >
        {he.decode(item.title)}{" "}
        <span className="symbol">{subMenuIsOpen ? "–" : "+"}</span>
      </SideNavLink>
      {item.subMenuItems && (
        <SubMenu
          className={`sub-menu ${subMenuIsOpen ? "open" : "closed"}`}
          pose={subMenuIsOpen ? "open" : "closed"}
        >
          {item.subMenuItems.map((subitem, i) => {
            return (
              <li key={i}>
                <SideNavLink
                  linkColor={linkColor}
                  size={size}
                  className="sub-menu-item"
                  activeClassName="active"
                  to={subitem.path}
                  onClick={handleParentItemClick}
                >
                  {he.decode(subitem.title)}
                </SideNavLink>
              </li>
            )
          })}
        </SubMenu>
      )}
    </>
  )
}

export default NavParentItem
